import React from "react"
import __c from "../utilities/combineClasses"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SignUpProcess from "../components/sign-up-process"

import style from "./sign-up.module.scss"

export default ({ location }) => {
  return (
    <Layout className={style.gridLayout}>
      <SEO title="Sign up today" />
      <article className={__c(style.article)}>
        <header className={__c(style.header)}>
          <h1 className={__c(style.pageTitle)}>Sign Up</h1>
          <div className={__c(style.introduction)}>
            <p>
              We&apos;re excited to show you how VariaMetrix can improve your
              data visualisation and reporting workflow. Answering just three
              questions gets you a free VariaMetrix dashboard that connects with
              your existing Google Analytics account.
            </p>
            <p>
              Call us at <a href="tel:+443337721488">(0333) 772 1488</a> to speak
              to an expert about setting up your account and getting the most
              out of VariaMetrix.
            </p>
          </div>
        </header>
        <main className={__c(style.main)}>
          <SignUpProcess
            className={__c(style.form)}
            chosenCollection={
              location.state ? location.state.chosenCollection : false
            }
            chosenRate={location.state ? location.state.chosenRate : false}
          />
        </main>
      </article>
    </Layout>
  )
}
