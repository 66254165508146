import React from "react"
import __c from "../utilities/combineClasses"
import ls from "local-storage"

import style from "./sign-up-process.module.scss"

const sharpSpringEndpoint =
  "https://app-3QNNTJ5PD0.marketingautomation.services/webforms/receivePostback/MzawMLEwMTIzAQA/62631a82-0145-49ef-9e45-1d74252f4e30/jsonp"

export default class SignUpProcess extends React.Component {
  constructor(props) {
    super(props)
    this.componentID = Math.floor(Math.random() * 1000)
    this.state = {
      name: "",
      org: "",
      email: "",
      status: `unsent`,
      errorMessage: ``,
      paymentProcess:
        Math.random() >= 0.5 ? `forceCapturePayment` : `lazyCapturePayment`,
      variaCollection: this.props.chosenCollection
        ? this.props.chosenCollection
        : `free`,
      paymentFrequency: this.props.chosenRate ? this.props.chosenRate : `month`,
      pricingFormABTest: ls.get(`pricingFormABTest`),
      affiliateCode: ls.get(`affiliateCode`) || `AWM`,
    }
  }

  handleInputChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  composeRequestBody = () => {
    const tiers = { free: 0, connect: 1, collect: 2 }
    return {
      Id: Math.floor(Math.random() * 16777215).toString(16),
      Account: {
        Name: this.state.org,
        LegalName: `---Request on Payment Provision---`,
        Domain: this.state.email.split("@")[1],
        PaymentProcess: this.state.paymentProcess,
        VariaCollection: tiers[this.state.variaCollection] || 0,
        Interval: this.state.paymentFrequency,
        ConversionPage: this.state.pricingFormABTest,
        AffiliateCode: this.state.affiliateCode,
      },
      User: {
        Name: this.state.name,
        Email: this.state.email,
        Role: `Admin`,
      },
    }
  }

  getTrackingCookie = () => {
      var b = document.cookie.match('(^|;)\\s*' + '__ss_tk' + '\\s*=\\s*([^;]+)');
      return b ? b.pop() : '';
  }

  trySubmit = (event) => {
    event.preventDefault()
    this.setState({
      status: `loading`,
    })

    /* Submit to SharpSpring */
    var tracking = this.getTrackingCookie()
    var formHeaders = new Headers()
    formHeaders.append(
      "Content-Type",
      "application/x-www-form-urlencoded;charset=UTF-8"
    )
    formHeaders.append("X-Requested-With", "XMLHttpRequest")
    var urlencoded = new URLSearchParams()
    let nameArray = this.state.name.split(" ")
    urlencoded.append(`given_name`, nameArray.shift())
    urlencoded.append(`surname`, nameArray.join(" "))
    urlencoded.append(`company`, this.state.org)
    urlencoded.append(`email`, this.state.email)
    urlencoded.append(`interval`, this.state.paymentFrequency)
    urlencoded.append(`collection`, this.state.variaCollection)
    urlencoded.append(`affiliate`, this.state.affiliateCode)
    urlencoded.append(`trackingid__sb`, tracking)

    let result = fetch(
      `${sharpSpringEndpoint}/jsonp/?${urlencoded.toString()}`,
      {
        method: "GET",
        mode: "no-cors",
        headers: formHeaders,
        redirect: "follow",
      }
    )

    /* Submit to VariaMetrix */
    const request = JSON.stringify(this.composeRequestBody())
    fetch("https://d2apiprod.azurewebsites.net/api/test/new/signup", {
      method: `POST`,
      body: request,
      headers: {
        "Content-Type": `application/json`,
      },
    })
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          response.json().then((data) => {
            // Handle response
            if (response.status !== 200) {
              this.setState({
                nextText: `---`,
                status: `error`,
                errorMessage: data.ErrorMessage,
              })
            }
            // Display confirmation
            else {
              if (data.Status === 200) {
                this.setState({
                  status: `success`,
                })
              } else {
                this.setState({
                  status: `error`,
                  errorMessage: data.ErrorMessage,
                })
              }
            }
          })
        } else {
          this.setState({
            status: `error`,
            errorMessage: response.statusText,
          })
        }
      })
      .catch((err) => {
        console.error(err)
        this.setState({
          status: `error`,
          errorMessage: err.message,
        })
      })
  }

  render = () => {
    const { className } = this.props
    return (
      <section
        className={__c([style.component, className])}
        data-status={this.state.status}
        data-theme="callout"
      >
        <header className={__c(style.header)}>
          <h1 className={__c(style.title)}>Let&apos;s get started!</h1>
          <p>You don&apos;t even need a credit card.</p>
        </header>
        <main className={__c(style.main)} data-theme="extra-callout">
          <form action="">
            <div
              className={__c([
                style.formPage,
                style.formStateUnsent,
                this.state.status === `unsent` || this.state.status === `error`
                  ? style.formPageActive
                  : ``,
              ])}
            >
              <div className={__c([`fieldset`, style.fieldSet])}>
                <label className={__c(style.fieldLabel)} htmlFor="name">
                  Your name
                </label>
                <input
                  autoComplete="off"
                  name="name"
                  id={`name-${this.componentID}`}
                  type="text"
                  value={this.state.name}
                  onChange={this.handleInputChange}
                  disabled={this.state.status === `success`}
                />
              </div>
              <div className={__c([`fieldset`, style.fieldSet])}>
                <label className={__c(style.fieldLabel)} htmlFor="org">
                  Organization
                </label>
                <input
                  autoComplete="off"
                  name="org"
                  id={`org-${this.componentID}`}
                  type="text"
                  value={this.state.org}
                  onChange={this.handleInputChange}
                  disabled={this.state.status === `success`}
                />
              </div>
              <div className={__c([`fieldset`, style.fieldSet])}>
                <label className={__c(style.fieldLabel)} htmlFor="email">
                  Email
                </label>
                <input
                  autoComplete="off"
                  name="email"
                  id={`email-${this.componentID}`}
                  type="email"
                  value={this.state.email}
                  onChange={this.handleInputChange}
                  disabled={this.state.status === `success`}
                />
              </div>
              <div className={__c([`buttonset`, style.fieldSet])}>
                <button
                  className={__c(`aside-button`)}
                  onClick={this.trySubmit}
                  disabled={this.state.status === `success`}
                >
                  Sign Up
                </button>
              </div>
            </div>
          </form>
        </main>
        <footer className={__c([style.footer, style.messages])}>
          <div
            className={__c([
              style.message,
              this.state.status === `invalid` ? style.isActive : false,
            ])}
          >
            <p>
              Please check the indicated fields above to make sure everything is
              correct and entered fully.
            </p>
          </div>
          <div
            className={__c([
              style.message,
              this.state.status === `loading` ? style.isActive : false,
            ])}
          >
            <p>Loading...</p>
          </div>
          <div
            className={__c([
              style.message,
              this.state.status === `error` ? style.isActive : false,
            ])}
          >
            <p>
              We couldn&apos;t sign you up. Please call{" "}
              <a href="tel:+18882030423">(888) 203 0423</a> to ask a
              representative to set up an account for you.
            </p>
          </div>
          <div
            className={__c([
              style.message,
              this.state.status === `success` ? style.isActive : false,
            ])}
          >
            <p>
              Congratulations! We&apos;re setting your account up now. Check
              your email to set your password and get started.
            </p>
          </div>
        </footer>
      </section>
    )
  }
}
